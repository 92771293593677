import { Box, Checkbox, Group, Pill, Popover, Text } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ExpandMoreRounded } from '../../icons';
import classes from './MultiSelectDropdown.module.css';

export type MultiSelectDropdownOptionProp = {
  checked?: boolean;
  value: string;
  label: string;
};

function MultiSelectDropdown({
  availableOptions,
  defaultValues = '',
  onChange,
  label,
  width = 350,
}: {
  availableOptions: MultiSelectDropdownOptionProp[];
  onChange: (values: MultiSelectDropdownOptionProp[]) => void;
  label: string;
  defaultValues?: string;
  width?: number;
}) {
  const [options, setOptions] = useState<
    MultiSelectDropdownOptionProp[] | undefined
  >();

  const [values, handlers] = useListState(options);

  useEffect(() => {
    if (!defaultValues || defaultValues === '') {
      handlers.apply((item) => ({ ...item, checked: false }));
    }
  }, [defaultValues]);

  useEffect(() => {
    const data = availableOptions.map((s) => {
      return {
        ...s,
        checked: defaultValues.includes(s.label),
      };
    });
    setOptions(data);
    handlers.setState(data);
  }, [availableOptions]);

  const selectedValues = values.filter((v) => v.checked);

  return (
    <Popover
      width={width}
      position="bottom"
      clickOutsideEvents={['mouseup', 'touchend']}
    >
      <Popover.Target>
        <Group
          w={width}
          justify="space-between"
          className={classes.InputWrapper}
        >
          <Group gap={2}>
            {selectedValues.map((s, i) => (
              <Box key={i + s.label}>
                {i === 0 && <Pill>{s.label}</Pill>}
                {i === 1 && <Pill>{selectedValues.length - 1}+</Pill>}
                <></>
              </Box>
            ))}
            <Text c="rgba(76, 84, 89, 0.42)">{label}</Text>
          </Group>

          <ExpandMoreRounded fill="var(--action-active)" />
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <Box mih="20vh" mah="50vh" style={{ overflowY: 'auto' }}>
          {values?.map((s, i) => (
            <Checkbox
              key={s.label + i}
              label={s.label}
              checked={s.checked}
              mt="md"
              onChange={(event) => {
                handlers.setItemProp(i, 'checked', event.currentTarget.checked);

                let data = [...values.filter((v) => v.checked)];
                if (event.currentTarget.checked) {
                  data.push({ ...s, checked: true });
                } else {
                  data = data.filter((d) => d.value !== s.value);
                }

                onChange(data);
              }}
            />
          ))}
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
}

export default MultiSelectDropdown;
