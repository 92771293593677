import { Group, Paper, SimpleGrid, Skeleton, Stack, rem } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Body2 from '~/components/Typograph/Body2';
import H4 from '~/components/Typograph/H4';
import H5 from '~/components/Typograph/H5';
import { useUserStatistic } from '~/services/user';

function MemberStatistic() {
  const { t } = useTranslation();
  const { data, isLoading } = useUserStatistic();
  const stat = {
    count: data?.data.total_user ?? 0,
    label: t('manage_members.label.total_members'),
    detailed_data: [
      {
        count: data?.data.total_active ?? 0,
        label: t('manage_members.label.active'),
      },
      {
        count:
          (data?.data.total_invited ?? 0) +
          (data?.data.total_not_verified ?? 0),
        label: t('manage_members.label.not_verified'),
      },
      {
        count: data?.data.total_deactivated ?? 0,
        count_color: 'var(--text-primary)',
        label: t('manage_members.label.deactivated'),
        label_color: 'var(--text-secondary)',
      },
    ],
  };
  return (
    <Skeleton radius={rem(8)} visible={isLoading}>
      <Paper
        shadow="none"
        radius={rem(8)}
        bd="1px solid var(--primary-main)"
        p="lg"
      >
        <Group w="100%">
          <Group w="50%">
            <H4 c="var(--primary-main)">{stat.count}</H4>
            <Body2 c="var(--text-primary)">{stat.label}</Body2>
          </Group>
          <SimpleGrid flex={1} cols={stat.detailed_data.length}>
            {stat.detailed_data.map((detail, i) => (
              <Stack gap={5} key={i}>
                <H5 c={detail.count_color ?? 'var(--primary-light)'}>
                  {detail.count}
                </H5>
                <Body2 c={detail.label_color ?? 'var(--text-primary)'}>
                  {detail.label}
                </Body2>
              </Stack>
            ))}
          </SimpleGrid>
        </Group>
      </Paper>
    </Skeleton>
  );
}

export default MemberStatistic;
