import { Flex, Pagination, Select } from '@mantine/core';
import { useSearchParams } from '@remix-run/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Caption from '../Typograph/Caption';

export type PaginationProps = {
  total: number;
  perPage?: number;
  currentPage?: number;
  onHandlePageChange: (arg0: number) => void;
  onHandleLimitChange: (arg0: number) => void;
};

function PaginationComponent({
  total,
  perPage = 10,
  currentPage = 1,
  onHandlePageChange,
  onHandleLimitChange,
}: PaginationProps) {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(perPage.toString());
  const rowsPerPageOptions = ['5', '10', '15', '20', '25'];

  const [searchParams, setParams] = useSearchParams();

  const onPageChange = (page: number) => {
    onHandlePageChange(page);
    searchParams.set('page', page.toString());
    setParams(searchParams);
  };
  const onLimitChange = (limit: string) => {
    searchParams.set('limit', limit);
    searchParams.set('page', '1');

    setParams(searchParams);
    onHandleLimitChange(parseInt(limit));
  };

  if (total === 0) return <></>;

  return (
    <Flex justify="space-between" align="center">
      <Flex align="center" gap={15}>
        <Caption>{t('general-component.label.rows_per_page')}</Caption>
        <Select
          data={rowsPerPageOptions}
          value={rowsPerPage}
          onChange={(val) => {
            if (val) {
              setRowsPerPage(val);
              onLimitChange(val);
            }
          }}
          w={50}
          withCheckIcon={false}
        />
      </Flex>
      <Pagination
        total={total}
        value={currentPage}
        onChange={(page) => onPageChange(page)}
      />
    </Flex>
  );
}

export default PaginationComponent;
