import { AppShell } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { isRouteErrorResponse, Outlet, useRouteError } from '@remix-run/react';
import { ErrorPage } from '~/components/ErrorPage';
import { Navbar } from '~/components/Layouts/LandingLayout/Navbar';
import { MEDIA_QUERY } from '~/constants';

function CommonLayout() {
  const matches = useMediaQuery(MEDIA_QUERY.xs);
  return (
    <AppShell
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: matches } }}
      footer={{ height: 388, offset: false }}
    >
      <AppShell.Navbar>
        <Navbar />
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return <ErrorPage code={error.status} />;
  }

  return (
    <>
      <h1>Error!</h1>
      <p>{JSON.stringify(error) ?? 'Unknown error'}</p>
    </>
  );
}

export default CommonLayout;
