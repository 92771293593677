import { Pill } from '@mantine/core';

function MemberStatusComponent({ status }: { status: string }) {
  switch (status) {
    case 'active':
      return (
        <Pill bg="var(--secondary-main)" c="var(--secondary-contrast)">
          Active
        </Pill>
      );
    case 'deactivated':
      return (
        <Pill bg="var(--action-selected)" c="var(--text-primary)">
          Deactivated
        </Pill>
      );
    case 'blocked':
      return (
        <Pill bg="var(--error-soft)" c="var(--secondary-contrast)">
          Blocked
        </Pill>
      );
    case 'invited':
      return (
        <Pill bg="var(--info-light)" c="var(--secondary-contrast)">
          Invited
        </Pill>
      );
    case 'not_verified':
      return (
        <Pill bg="var(--warning-soft)" c="var(--secondary-contrast)">
          Not Verified
        </Pill>
      );

    default:
      return <></>;
  }
}

export default MemberStatusComponent;
