import { NotificationData, notifications } from '@mantine/notifications';
import {
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  InfoRounded,
  WarningAmberRounded,
} from '../icons';
import Body1 from '../Typograph/Body1';
import Body2 from '../Typograph/Body2';

const success = ({ title, message, ...props }: NotificationData) =>
  notifications.show({
    styles: {
      root: {
        background: 'var(--alert-success-fill)',
      },
      icon: {
        background: 'none',
      },
    },
    icon: (
      <CheckCircleOutlineRounded
        fill="var(--success-main)"
        height={22}
        width={22}
      />
    ),
    title: title && (
      <Body1 fw="bold" c={`var(--success-dark)`}>
        {title}
      </Body1>
    ),
    message: message && (
      <Body2 c={`var(--success-dark)`} display="inline-block">
        {message}
      </Body2>
    ),
    withCloseButton: false,
    ...props,
  });

const error = ({ title, message, ...props }: NotificationData) =>
  notifications.show({
    styles: {
      root: {
        background: 'var(--alert-error-fill)',
      },
      icon: {
        background: 'none',
      },
    },
    icon: (
      <ErrorOutlineRounded fill="var(--error-main)" height={22} width={22} />
    ),
    title: title && (
      <Body1 fw="bold" c={`var(--error-dark)`}>
        {title}
      </Body1>
    ),
    message: message && (
      <Body2 c={`var(--error-dark)`} display="inline-block">
        {message}
      </Body2>
    ),
    withCloseButton: false,
    ...props,
  });

const warning = ({ title, message, ...props }: NotificationData) =>
  notifications.show({
    styles: {
      root: {
        background: 'var(--alert-warning-fill)',
      },
      icon: {
        background: 'none',
      },
    },
    icon: (
      <WarningAmberRounded fill="var(--warning-main)" height={22} width={22} />
    ),
    title: title && (
      <Body1 fw="bold" c={`var(--warning-dark)`}>
        {title}
      </Body1>
    ),
    message: message && (
      <Body2 c={`var(--warning-dark)`} display="inline-block">
        {message}
      </Body2>
    ),
    withCloseButton: false,
    ...props,
  });

const info = ({ title, message, ...props }: NotificationData) =>
  notifications.show({
    styles: {
      root: {
        background: 'var(--alert-info-fill)',
      },
      icon: {
        background: 'none',
      },
    },
    icon: <InfoRounded fill="var(--info-main)" height={22} width={22} />,
    title: title && (
      <Body1 fw="bold" c={`var(--info-dark)`}>
        {title}
      </Body1>
    ),
    message: message && (
      <Body2 c={`var(--info-dark)`} display="inline-block">
        {message}
      </Body2>
    ),
    withCloseButton: false,
    ...props,
  });

export default {
  success,
  error,
  warning,
  info,
};
