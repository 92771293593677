import { TFunction } from 'i18next';

export const memberStatusOptions = (t: TFunction<'translation', undefined>) => [
  {
    label: t('manage_members.label.active'),
    value: 'active',
  },
  {
    label: t('manage_members.label.deactivated'),
    value: 'deactivated',
  },
  {
    label: t('manage_members.label.not_verified'),
    value: 'not_verified',
  },
];

export const sortOptions = (t: TFunction<'translation', undefined>) => {
  return [
    {
      label: t('manage-resources.label.sort_by_az'),
      value: 'name-ASC',
    },
    {
      label: t('manage_members.label.sort_by_latest_active'),
      value: 'last_active_at-DESC',
    },
    {
      label: t('manage-resources.label.sort_by_newest_member'),
      value: 'created_at-DESC',
    },
    {
      label: t('manage-resources.label.sort_by_oldest_member'),
      value: 'created_at-ASC',
    },
  ];
};
