import {
  Anchor,
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Group,
  Menu,
  Select,
  Stack,
  Text,
  TextInput,
  rem,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { useNavigate, useSearchParams } from '@remix-run/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyList } from '~/components/EmptyList';
import { SearchResult } from '~/components/SearchResult';
import {
  PaginationComponent,
  TableComponent,
} from '~/components/TableComponent';
import { TableProps } from '~/components/TableComponent/TableComponent';
import {
  CancelRounded,
  CheckCircleRounded,
  ExpandMoreRounded,
  FilterListRounded,
  MoreVertRounded,
  SearchRounded,
} from '~/components/icons';

import debounce from 'debounce';
import { MultiSelectDropdown } from '~/components/Input/MultiSelectDropdown';
import { MultiSelectDropdownOptionProp } from '~/components/Input/MultiSelectDropdown/MultiSelectDropdown';
import Notify from '~/components/Notify/Notify';
import Body2 from '~/components/Typograph/Body2';
import Caption from '~/components/Typograph/Caption';
import { countryList } from '~/constants';
import { useUserBlock, useUserListPagination } from '~/services/user';
import { UserBlockRequest } from '~/services/user/UserBlock';
import { UserDetail } from '~/services/user/UserList';
import { formatDate } from '~/utils/format';
import { MemberStatusComponent } from '.';
import { memberStatusOptions, sortOptions } from './options';

export default function MemberTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setParams] = useSearchParams();

  const [q, setQ] = useState(searchParams.get('q') ?? '');
  const [filterLocations, setFilterLocations] = useState(
    searchParams.get('locations') ?? ''
  );

  const [page, setPage] = useState(searchParams.get('page') ?? '1');
  const [limit, setLimit] = useState(searchParams.get('limit') ?? '10');

  const [selectedSortBy, setSelectedSortBy] = useState(
    searchParams.get('sort_by') || 'created_at-DESC'
  );
  const [statusMember, setStatusMember] = useState(searchParams.get('status'));
  const [locationOptions, setLocationOptions] = useState<
    MultiSelectDropdownOptionProp[]
  >([]);

  useEffect(() => {
    if (!locationOptions.length) {
      setLocationOptions(countryList.map((c) => ({ label: c, value: c })));
    }
  }, []);

  const handleLocationsChange = async (
    values: MultiSelectDropdownOptionProp[]
  ) => {
    const d = values
      .filter((v) => v.checked)
      .map((s) => s.label)
      .join(',');
    setFilterLocations(d);
    if (d.length) {
      searchParams.set('locations', d);
    } else {
      searchParams.delete('locations');
    }
    setParams(searchParams);
    await refetch();
    await refetch();
  };

  const {
    data,
    isLoading = true,
    refetch,
  } = useUserListPagination({
    payload: {
      q: q ?? '',
      page: +parseInt(page ?? '1'),
      limit: parseInt(limit ?? '10'),
      status: statusMember ?? '',
      sort_by: selectedSortBy.split('-')[0],
      sort_order: selectedSortBy.split('-')[1],
      location: filterLocations,
    },
  });

  const { mutateAsync: mutateBlockUser } = useUserBlock({
    onError: () => {
      Notify.error({
        name: 'user_blocked',
        message: t('general-component.error.failed_block_unblock_user'),
      });
    },
    onSuccess: async () => {
      Notify.success({
        name: 'user_blocked',
        message: (
          <>
            <Text component="span" fw="bold" c="var(--success-dark)">
              {t('general-component.response.success_block_unblock_user')}
            </Text>
          </>
        ),
      });
      refetch();
    },
  });

  const blockUser = (id: number, block: boolean) => {
    const payload: UserBlockRequest = {
      user_id: id,
      block,
    };
    mutateBlockUser(payload as never);
  };

  const openBlockConfirmModal = (user: UserDetail, block: boolean) =>
    modals.openConfirmModal({
      title: `${block ? 'Block' : 'Unblock'} ${user.name} ?`,
      children: (
        <Caption c="var(--text-secondary)">
          {block
            ? 'Are you sure you want to block this member ? Blocking this member will restrict their access to the platform and any associated resources until they’re unblocked.'
            : 'Are you sure you want to unblock this member? Unblocking this member will restore their access to the platform and associated resources.'}
        </Caption>
      ),
      labels: {
        confirm: block ? 'Block Member' : 'Unblock Member',
        cancel: 'Cancel',
      },
      confirmProps: {
        color: block ? 'var(--error-light)' : 'var(--primary-main)',
      },
      onCancel: () => {},
      onConfirm: () => {
        blockUser(user.id, block);
      },
    });

  const tableOptions: TableProps<UserDetail> = useMemo(
    () => ({
      data: data?.pages?.[0].data ?? [],
      isSelectable: false,
      isLoading,
      tableName: 'manage-members-table',
      columns: [
        {
          id: 'name',
          label: 'Member',
          render(rowData) {
            return (
              <Flex gap={rem(10)}>
                <Avatar src={rowData.avatar_url}>{rowData.name[0]}</Avatar>

                <Stack gap={5}>
                  <Anchor
                    c="var(--primary-main)"
                    fw={600}
                    underline="never"
                    onClick={() => navigate('./' + rowData.username)}
                  >
                    {rowData.name}
                  </Anchor>
                  <Caption c="var(--text-tertiary)">
                    @{rowData.username}
                  </Caption>
                </Stack>
              </Flex>
            );
          },
        },
        {
          id: 'email',
          label: 'Email Address',
        },
        {
          id: 'location',
          label: 'Location',
        },
        {
          id: 'job_title',
          label: 'Job Title',
          render(rowData) {
            return (
              <Stack gap={5}>
                <Body2 c="var(--text-secondary)">{rowData.job_title}</Body2>
                <Caption c="var(--text-tertiary)">
                  {rowData.years_of_experience
                    ? `${rowData.years_of_experience} Years`
                    : '-'}
                </Caption>
              </Stack>
            );
          },
        },
        {
          id: 'is_receive_notification',
          label: 'Marketing Email',
          render(rowData) {
            return (
              <Center>
                {rowData.is_receive_notification && (
                  <CheckCircleRounded fill="var(--primary-main)" />
                )}
                {!rowData.is_receive_notification && (
                  <CancelRounded fill="var(--error-main)" />
                )}
              </Center>
            );
          },
        },
        {
          id: 'last_active_at',
          label: 'Last Active',
          render(rowData) {
            const formattedDate = formatDate(
              rowData.last_active_at,
              'DD MMM YYYY'
            );
            return formattedDate === '01 Jan 0001' ? '-' : formattedDate;
          },
        },
        {
          id: 'status',
          label: 'Status',
          render(rowData) {
            return <MemberStatusComponent status={rowData.status} />;
          },
        },
        {
          id: '',
          label: 'Action',
          render(rowData) {
            return (
              <Menu shadow="md" position="bottom-end">
                <Menu.Target>
                  <Button variant="transparent">
                    <MoreVertRounded fill="var(--action-active)" />
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => {
                      navigate(`./${rowData.id}/update`);
                    }}
                  >
                    {t('general-component.button.update_profile')}
                  </Menu.Item>
                  {rowData.status === 'invited' && (
                    <Menu.Item
                    // c="var(--error-main)"
                    >
                      {t('manage_members.button.resend_invitation')}
                    </Menu.Item>
                  )}
                  {rowData.status === 'blocked' ? (
                    <Menu.Item
                      onClick={() => {
                        openBlockConfirmModal(rowData, false);
                      }}
                    >
                      {t('general-component.button.unblock_member')}
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      c="var(--error-main)"
                      onClick={() => {
                        openBlockConfirmModal(rowData, true);
                      }}
                    >
                      {t('general-component.button.block_member')}
                    </Menu.Item>
                  )}
                </Menu.Dropdown>
              </Menu>
            );
          },
        },
      ],
    }),
    [data, isLoading]
  );

  const isFiltering =
    q !== '' || filterLocations !== '' || statusMember !== null;

  const resetFilter = async () => {
    setParams();
    setQ('');
    setFilterLocations('');
    setStatusMember(null);
    setSelectedSortBy('created_at-DESC');

    await refetch();
    await refetch();
  };

  const handleSearch = useCallback(
    debounce(async () => {
      await refetch();
      await refetch();
    }, 400),
    [refetch]
  );

  return (
    <Stack>
      <Group gap={16}>
        <Box pb={5} w="70%">
          <TextInput
            size="md"
            flex={1}
            value={q}
            leftSection={<SearchRounded fill="var(--action-active)" />}
            placeholder={t('manage-forums.label.search')}
            onChange={async (e) => {
              setQ(e.target.value);
              if (e.target.value) {
                searchParams.set('q', e.target.value);
              } else {
                searchParams.delete('q');
              }
              setParams(searchParams);
              handleSearch();
            }}
          />
        </Box>

        <Box pb={5} w="15%">
          <MultiSelectDropdown
            defaultValues={filterLocations}
            availableOptions={locationOptions}
            onChange={handleLocationsChange}
            label="Location"
          />
        </Box>

        <Select
          width="15%"
          data={memberStatusOptions(t)}
          value={statusMember}
          rightSection={<ExpandMoreRounded fill="var(--action-active)" />}
          placeholder={t('manage-forums.label.status')}
          rightSectionPointerEvents="none"
          onChange={async (e) => {
            setStatusMember(e);
            if (e) {
              searchParams.set('status', e);
            } else {
              searchParams.delete('status');
            }
            setParams(searchParams);
            await refetch();
            await refetch();
          }}
        />
        <Menu shadow="md">
          <Menu.Target>
            <Button variant="transparent">
              <FilterListRounded fill="var(--action-active)" />
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            {sortOptions(t)?.map((s, i) => (
              <Menu.Item
                onClick={async () => {
                  setSelectedSortBy(s.value);
                  searchParams.set('sort_by', s.value);
                  setParams(searchParams);

                  await refetch();
                  await refetch();
                }}
                key={i}
              >
                {s.label}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
      {isFiltering && (
        <SearchResult
          total={data?.pages?.[0].total ?? 0}
          resetFilter={resetFilter}
        />
      )}
      {isFiltering && data?.pages?.[0].total === 0 ? (
        <EmptyList
          title={t('community-forum.label.no_result')}
          subtitle={t('manage_members.label.empty_result_subtitle')}
        />
      ) : (
        <>
          <TableComponent {...tableOptions} />
          <PaginationComponent
            total={data?.pages?.[0].total_page ?? 0}
            perPage={parseInt(limit)}
            currentPage={parseInt(page)}
            onHandlePageChange={async (page) => {
              setPage(page.toString());
              await refetch();
              await refetch();
            }}
            onHandleLimitChange={async (limit) => {
              setLimit(limit.toString());
              setPage('1');
              await refetch();
              await refetch();
            }}
          />
        </>
      )}
    </Stack>
  );
}
