export type PayloadProps = {
  q?: string;
  page?: number;
  sort_by?: string;
  sort_order?: string;
  limit?: number;
};

export const DefaultPayload = {
  q: '',
  page: 1,
  sort_by: 'created_at',
  sort_order: 'DESC',
  limit: 10,
};

export const AuthorDefaultPayload = {
  q: '',
  page: 1,
  sort_by: 'alphabetical_order',
  limit: 10000,
};

export interface BaseRequestError {
  message?: string;
  code?: string;
  request?: any;
  response?: BaseRequestErrorResponse;
}

export interface BaseRequestErrorResponse {
  data: BaseRequestErrorResponseData;
}

export interface BaseRequestErrorResponseData {
  code: number;
  message: string;
}
