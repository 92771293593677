import { LogoText } from '~/assets/logo';

export type MetaProps = {
  title?: string;
  description?: string;
  logo?: string;
};

export function NexusMeta({ title, description, logo = LogoText }: MetaProps) {
  return [
    { title: title ? title + ' | Nexus' : 'Nexus' },
    {
      name: 'description',
      content: description || 'Nexus',
    },
    {
      tagName: 'link',
      rel: 'canonical',
      href: 'https://nexushehub.com',
    },
    {
      property: 'og:url',
      content: logo,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:title',
      content: title ?? title + ' | Nexus',
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: LogoText,
    },
  ];
}
