import { Button, Group, Image, Space, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { NavLink, useNavigate } from '@remix-run/react';
import logo from '~/assets/logo/logo.png?url';
import {
  AnalyticsRounded,
  CastforEducationRounded,
  EventAvailableRounded,
  ForumRounded,
  GroupsRounded,
  LogoutRounded,
  NewspaperRounded,
  PersonPinRounded,
} from '~/components/icons';
import { SignoutModal } from '~/components/Modal';
import { useAuthStore } from '~/stores/useAuthStores';
import classes from './Navbar.module.css';

export type NavbarMenu = {
  title: string;
  icon?: JSX.Element;
  href: string;
  children?: NavbarMenu[];
};

const menus: NavbarMenu[] = [
  {
    title: 'Manage Members',
    icon: <GroupsRounded fill="var(--primary-main)" />,
    href: '/manage-members',
  },
  {
    title: 'Manage Authors',
    icon: <PersonPinRounded fill="var(--primary-main)" />,
    href: '/manage-authors',
  },
  {
    title: 'Manage Resources',
    icon: <CastforEducationRounded fill="var(--primary-main)" />,
    href: '/manage-resources',
    children: [
      {
        title: 'Resource List',
        href: '/manage-resources',
      },
      {
        title: 'Resource Access',
        href: '/manage-resource-access',
      },
    ],
  },
  {
    title: 'Manage Articles',
    icon: <NewspaperRounded fill="var(--primary-main)" />,
    href: '/manage-articles',
  },
  {
    title: 'Manage Events',
    icon: <EventAvailableRounded fill="var(--primary-main)" />,
    href: '/manage-events',
  },
  {
    title: 'Manage Forums',
    icon: <ForumRounded fill="var(--primary-main)" />,
    href: '/manage-forums',
  },
  {
    title: 'Reports & Analytics',
    icon: <AnalyticsRounded fill="var(--primary-main)" />,
    href: '/reports',
  },
];

function Navbar() {
  const { logout } = useAuthStore();
  const navigate = useNavigate();
  const [signoutModal, { toggle: toggleSignoutModal }] = useDisclosure(false);
  return (
    <Stack p="xl" bg="var(--secondary-light)" h="100%">
      <Image loading="lazy" src={logo} height={64} />
      <Space h="lg" />
      {menus.map((menu, index) => (
        <NavLink
          key={menu.title + index}
          to={menu.href}
          className={({ isActive }) =>
            !menu.children && isActive ? classes.NavLinkActive : classes.NavLink
          }
        >
          <Group>
            {menu.icon}
            {menu.title}
          </Group>
          {menu.children && (
            <Stack pt={10} ml="lg" gap="xs">
              {menu.children.map((child, i) => (
                <NavLink
                  key={child.title + i}
                  to={child.href}
                  className={({ isActive }) =>
                    isActive ? classes.NavLinkActive : classes.NavLink
                  }
                >
                  <Group>{child.title}</Group>
                </NavLink>
              ))}
            </Stack>
          )}
        </NavLink>
      ))}
      <Button
        mt="auto"
        rightSection={<LogoutRounded fill="var(--secondary-contrast)" />}
        justify="space-between"
        c="var(--secondary-contrast)"
        bg="var(--secondary-main)"
        fz={16}
        onClick={toggleSignoutModal}
      >
        Sign Out
      </Button>

      <SignoutModal
        openeda={signoutModal}
        onClose={toggleSignoutModal}
        onSubmit={() => {
          logout();
          return navigate('/login');
        }}
      />
    </Stack>
  );
}

export default Navbar;
