import { Button, Group, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import Subtitle2 from '~/components/Typograph/Subtitle2';

const SearchResult = ({
  total,
  resetFilter,
}: {
  total: number;
  resetFilter: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Stack flex={3}>
      <Group justify="space-between">
        <Subtitle2 c="var(--text-secondary)">
          {t('community-forum.label.search_result')} ({total})
        </Subtitle2>
        <Button variant="subtle" c="var(--error-main)" onClick={resetFilter}>
          {t('community-forum.button.reset_filter')}
        </Button>
      </Group>
    </Stack>
  );
};

export default SearchResult;
