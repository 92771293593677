import { Box, Center } from '@mantine/core';
import { lazy, Suspense } from 'react';
import animationData from './Loading.json';

const Lottie = lazy(() => import('lottie-react'));

function Loading({ width = 100 }: { width?: number }) {
  return (
    <Suspense fallback={<></>}>
      <Center>
        <Box w={width}>
          <Lottie animationData={animationData} loop />
        </Box>
      </Center>
    </Suspense>
  );
}

export default Loading;
