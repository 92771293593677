import { Container, Stack, rem } from '@mantine/core';
import { MetaFunction } from '@remix-run/react';
import { Breadcrumbs } from '~/components/Breadcrumbs';
import { NexusMeta } from '~/components/Meta';
import { MemberStatistic, MemberTable } from './fragments';

export const meta: MetaFunction = () => {
  return NexusMeta({
    title: 'Manage Members',
    description: 'Manage Members',
  });
};

function ManageMembersPage() {
  return (
    <Container fluid px={rem(40)} py={rem(20)}>
      <Stack>
        <Breadcrumbs title="Manage Members" items={[]} showAvatar={true} />
        <MemberStatistic />
        <MemberTable />
      </Stack>
    </Container>
  );
}

export default ManageMembersPage;
