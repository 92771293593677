import { Title, TitleProps } from '@mantine/core';
import styles from '~/components/Typograph/Typograph.module.css';

type Props = TitleProps;

function H3(props: Props) {
  return (
    <Title fz={48} fs="normal" fw={300} className={styles.h3} {...props} />
  );
}

export default H3;
