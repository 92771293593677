import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { apiBearerIam } from '~/services/api';
import { HttpData } from '~/utils/network/HttpData';

export type UserBlockRequest = {
  user_id: number;
  block: boolean;
};

export type UserBlockResponse = {
  id: number;
};
export const useUserBlock = (
  config: UseMutationOptions<HttpData<UserBlockResponse>>
) => {
  return useMutation<HttpData<UserBlockResponse>, Error, void>({
    mutationKey: ['user', 'block'],

    mutationFn: async (payload) => {
      const response = await apiBearerIam({}).post<HttpData<UserBlockResponse>>(
        '/iam/user/v1/block',
        payload as unknown as UserBlockRequest
      );
      return response.data;
    },
    onSuccess(data, variables, context) {
      return { data, variables, context };
    },
    ...config,
  });
};
