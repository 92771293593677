import { useInfiniteQuery, UseQueryOptions } from '@tanstack/react-query';
import { HttpData } from '~/utils/network/HttpData';
import { apiBearerIam } from '../api';
import { DefaultPayload, PayloadProps } from '../PayloadProps';

export type UserDetail = {
  id: number;
  username: string;
  name: string;
  role: string;
  avatar_url?: string;
  is_active?: boolean;
  email: string;
  location: string;
  job_title: string;
  years_of_experience: number;
  is_receive_notification: boolean;
  last_active_at: string;
  status: string;
  created_at: string;
  updated_at: string;
  industry: string;
  organization: string;
  skill: string;
  topic_of_interest: string;
  professional_bio: string;
  preferred_learning_format: string;
  preferred_language: string;
  preferred_communication_channel: string;
  frequency_of_updates: string;
  font_size_preference: string;
  feature_to_support_learning: string;
};

export type UserListResponse = {
  data: UserDetail[];
  total: number;
  total_page: number;
  page_size: number;
  page: number;
};

export type UserListPage = {
  pages: UserListResponse[];
};

export type UserListPayload<T> = {
  config?: UseQueryOptions<T>;
  payload: PayloadProps & {
    location?: string;
    status?: string;
  };
};

export const useUserListPagination = (
  config: UserListPayload<HttpData<UserListResponse>>
) => {
  return useInfiniteQuery<UserListResponse, Error, UserListPage>({
    queryKey: ['user', 'list-pagination'],
    initialPageParam: 1,
    queryFn: async () => {
      const response = await apiBearerIam({}).get('/iam/user/v1', {
        params: {
          ...DefaultPayload,
          ...config.payload,
        },
      });
      return response.data.data || [];
    },
    getNextPageParam(lastPage) {
      return lastPage.total_page == lastPage.page
        ? undefined
        : lastPage.page + 1;
    },
    ...config,
  });
};
