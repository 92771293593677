import { createTheme, Input, MultiSelect, Select } from '@mantine/core';
import { ExpandMoreRounded } from '~/components/icons';
import InputClasses from './Input.module.css';
import InputWrapperClasses from './InputWrapper.module.css';

const { Wrapper: InputWrapper } = Input;

export const theme = createTheme({
  primaryColor: 'primary',
  colors: {
    primary: [
      '#e8fbf5',
      '#dcf0e8',
      '#bdded1',
      '#9acbb8',
      '#7cbba4',
      '#69b196',
      '#5dad8e',
      '#4c977b',
      '#3f876c',
      '#2e755c',
    ],
  },
  components: {
    Input: Input.extend({ classNames: InputClasses }),
    InputWrapper: InputWrapper.extend({
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      classNames: {
        ...InputWrapperClasses,
      },
    }),
    Select: Select.extend({
      defaultProps: {
        rightSection: <ExpandMoreRounded fill="var(--action-active)" />,
        rightSectionPointerEvents: 'none',
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        rightSection: <ExpandMoreRounded fill="var(--action-active)" />,
        rightSectionPointerEvents: 'none',
      },
    }),
  },
});
