import { Title, TitleProps } from '@mantine/core';
import styles from '~/components/Typograph/Typograph.module.css';

type Props = TitleProps & {
  clampLine?: boolean;
};

function H5(props: Props) {
  return (
    <Title
      lineClamp={props.clampLine ? 2 : undefined}
      fz={24}
      fs="normal"
      fw={300}
      style={{
        wordWrap: props.clampLine ? 'break-word' : undefined,
      }}
      className={styles.h5}
      {...props}
    />
  );
}

export default H5;
