import {
  ActionIcon,
  Anchor,
  Breadcrumbs as C,
  Group,
  Stack,
} from '@mantine/core';
import { useNavigate } from '@remix-run/react';
import React from 'react';
import AvatarAuthenticated from '../Layouts/LandingLayout/Header/AvatarAuthenticated';
import H5 from '../Typograph/H5';
import { ArrowBackRounded } from '../icons';

export type BreadcrumbsProps = {
  items: {
    href: string;
    title: string;
  }[];
  rightSection?: React.ReactNode;
  title?: string;
  showAvatar?: boolean;
  showBackButton?: boolean;
  onClickBackbutton?: void | null;
};

const Breadcrumbs = ({
  items,
  title,
  rightSection,
  showAvatar = false,
  showBackButton = false,
  onClickBackbutton = null,
}: BreadcrumbsProps) => {
  const navigate = useNavigate();

  const BreadcrumbComponent = () => (
    <C
      styles={{
        root: {
          fontSize: 16,
        },
      }}
    >
      {items.map((item, index) => (
        <Anchor
          href={item.href}
          key={`crumbs-${index}`}
          c={
            items.length - 1 !== index
              ? 'var(--text-secondary)'
              : 'var(--primary-main)'
          }
          onClick={(e) => {
            e.preventDefault();
            navigate(item.href);
          }}
        >
          {item.title}
        </Anchor>
      ))}
    </C>
  );
  return (
    <Group>
      <Stack w="100%">
        <Group gap={24} h={48} w="100%">
          {showBackButton && (
            <ActionIcon
              onClick={() => {
                if (onClickBackbutton) {
                  onClickBackbutton();
                  return;
                } else {
                  navigate(-1);
                }
              }}
              variant="transparent"
            >
              <ArrowBackRounded fill="var(--action-active)" />
            </ActionIcon>
          )}

          {title && (
            <H5 fw={300} fz={34}>
              {title}
            </H5>
          )}
          {!title && <BreadcrumbComponent />}
          <Group ml="auto">{showAvatar && <AvatarAuthenticated />}</Group>
        </Group>
        {title && <BreadcrumbComponent />}
      </Stack>
      {!!rightSection && rightSection}
    </Group>
  );
};

export default Breadcrumbs;
