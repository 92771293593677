import { useQuery } from '@tanstack/react-query';
import { apiBearerIam } from '~/services/api';
import { HttpData } from '~/utils/network/HttpData';

export type UserStatisticResponse = {
  total_user: number;
  total_active: number;
  total_invited: number;
  total_deactivated: number;
  total_not_verified: number;
};

export const useUserStatistic = () => {
  return useQuery<
    HttpData<UserStatisticResponse>,
    Error,
    HttpData<UserStatisticResponse>
  >({
    queryKey: ['user', 'statistic'],
    queryFn: async () => {
      const response = await apiBearerIam({}).get<
        HttpData<UserStatisticResponse>
      >('/iam/user/v1/stat');
      return response.data;
    },
  });
};
