import { Title, TitleProps } from '@mantine/core';
import styles from '~/components/Typograph/Typograph.module.css';

type Props = TitleProps;

function Body2(props: Props) {
  return (
    <Title fz={14} fs="normal" fw={400} className={styles.body2} {...props} />
  );
}

export default Body2;
