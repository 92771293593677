import dayjs from 'dayjs';
import { TFunction } from 'i18next';

export const formatDate = (date: string, format = 'DD MMMM YYYY') =>
  dayjs(date).format(format);

export const formatDateMachine = (date: string, format = 'DD MMMM YYYY') =>
  dayjs(date).format('YYYY-MM-DD');

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatDateLong = (date: string, format = 'DD MMMM YYYY | HH:mm') =>
  dayjs(date).format(format);

export const formatToHHMM = (
  t: TFunction<'translation', undefined>,
  num: number
) => {
  // Store the input number of minutes in a variable num
  // Calculate the total hours by dividing the number of minutes by 60
  const hours = num / 60;
  // Round down the total hours to get the number of full hours
  const rhours = Math.floor(hours);
  // Calculate the remaining minutes after subtracting the full hours from the total hours
  const minutes = (hours - rhours) * 60;
  // Round the remaining minutes to the nearest whole number
  const rminutes = Math.round(minutes);
  // Construct and return a string representing the conversion result
  const hourText = rhours > 0 ? `${rhours} ${t('common.hours')}` : '';
  const minutesText = rminutes > 0 ? `${rminutes} ${t('common.minutes')}` : '';
  return `${hourText} ${minutesText}`;
};
