/* eslint-disable no-case-declarations */
import { Button, Card, Group, Modal, Space, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Body2 from '../Typograph/Body2';

export type SignoutModalProps = {
  openeda: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

function SignoutModal({ openeda, onClose, onSubmit }: SignoutModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      opened={openeda}
      size="444"
      onClose={onClose}
      title={
        <Text fz={20} fs="normal" fw={300} c="var(--text-primary)">
          {t('general-component.label.signout_confirmation')}
        </Text>
      }
      centered
      styles={{
        header: { padding: 0, minHeight: 0 },
        body: { padding: 0 },
        content: { padding: 24 },
      }}
    >
      <Card>
        <Card.Section>
          <Space h="sm" />
          <Stack>
            <Body2 c="var(--text-secondary)">
              {t('general-component.label.signout_confirmation_subtitle')}
            </Body2>

            <Group gap={8} justify="end">
              <Button variant="outline" onClick={onClose}>
                {t('general-component.button.back')}
              </Button>
              <Button
                variant="filled"
                color="var(--error-main)"
                onClick={onSubmit}
              >
                Sign Out
              </Button>
            </Group>
          </Stack>
        </Card.Section>
      </Card>
    </Modal>
  );
}

export default SignoutModal;
