/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Container, Flex, Image, Stack } from '@mantine/core';
import { Link } from '@remix-run/react';
import { ErrorNotfound, InternalServerError } from '~/assets/images/error';
import H3 from '../Typograph/H3';
import H8 from '../Typograph/H8';

type ErrorSchemaProps = {
  image: string;
  title: string;
  desc: string[];
};

const ErrorSchema: Record<any, ErrorSchemaProps> = {
  404: {
    image: ErrorNotfound,
    title: `Oops! Page Not Found`,
    desc: [
      `The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.`,
      `Please don't hesitate to contact us at learning@nexushehub.com for further support.`,
    ],
  },
  500: {
    image: InternalServerError,
    title: `Oops! Internal Server Error`,
    desc: [
      `We're sorry, but something went wrong on our end. Our team has been notified and we're working to fix the problem.`,
      `Please don't hesitate to contact us at learning@nexushehub.com for further support.`,
    ],
  },
  503: {
    image: InternalServerError,
    title: `Oops! Service Unavailable`,
    desc: [
      `Our website is temporarily unable to service your request due to maintenance downtime or capacity problems.Try again later.`,
      `Please don't hesitate to contact us at learning@nexushehub.com for further support.`,
    ],
  },
  403: {
    image: InternalServerError,
    title: `Oops! Forbidden`,
    desc: [
      `You don't have permission to access this page. Please don't hesitate to contact us at learning@nexushehub.com for further support.`,
    ],
  },
};

type ErrorPageProps = {
  code: number;
};

const ErrorPage = ({ code }: ErrorPageProps) => {
  const { desc, image, title } = ErrorSchema?.[code] ?? ErrorSchema[500];

  return (
    <Container size="xl" py={120}>
      <Image alt={image} src={image} mb={60} height={400} fit="fill" />
      <Stack gap={24} align="center">
        <H3 c="var(--primary-main)" ta="center">
          {title}
        </H3>
        <div>
          {desc.map((t, i) => (
            <H8
              c="var(--text-secondary)"
              key={`text-index-error${i}`}
              ta="center"
            >
              {t}
            </H8>
          ))}
        </div>
        <Flex>
          <Button component={Link} to="/" mx="auto">
            Go to Homepage
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default ErrorPage;
