import {
  ActionIcon,
  Avatar,
  Button,
  Group,
  Menu,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link, useNavigate } from '@remix-run/react';
import { useLocale } from 'remix-i18next/react';
import DefaultAvatar from '~/assets/images/default-avatar.png?url';
import {
  ChevronRightRounded,
  LanguageRounded,
  LogoutRounded,
  NotificationsRounded,
  SettingsRounded,
} from '~/components/icons';
import { SignoutModal } from '~/components/Modal';
import Body1 from '~/components/Typograph/Body1';
import Body2 from '~/components/Typograph/Body2';
import { supportedLanguageList } from '~/config/i18n';
import { useAuthStore } from '~/stores/useAuthStores';

const AvatarAuthenticated = () => {
  const { isAuthenticated, userProp, logout } = useAuthStore();
  const navigate = useNavigate();
  const [signoutModal, { toggle: toggleSignoutModal }] = useDisclosure(false);

  if (isAuthenticated) {
    return (
      <>
        {/*<ActionIcon variant="transparent">*/}
        {/*  <NotificationsRounded fill="var(--primary-main)" />*/}
        {/*</ActionIcon>*/}
        <Menu
          width={200}
          position="bottom-end"
          radius={4}
          styles={{
            dropdown: { border: 'none', boxShadow: 'var(--elevation-8)' },
          }}
          trigger="click-hover"
        >
          <Menu.Target>
            <Avatar src={userProp.avatar_url ?? DefaultAvatar}>
              {userProp.name[0]}
            </Avatar>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item>
              <Group gap={16}>
                <Avatar src={userProp.avatar_url ?? DefaultAvatar}>
                  {userProp.name[0]}
                </Avatar>
                <Stack gap={4}>
                  <Body1>{userProp.name}</Body1>
                  <Body2>@{userProp.username}</Body2>
                </Stack>
              </Group>
            </Menu.Item>
            <Menu.Divider />
            {/* <Menu.Item
              leftSection={<PersonRounded fill="var(--action-active)" />}
            >
              My Profile
            </Menu.Item> */}
            <Menu.Item
              component={Link}
              to={'/settings'}
              leftSection={<SettingsRounded fill="var(--action-active)" />}
            >
              Settings
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              leftSection={<LogoutRounded fill="var(--error-main)" />}
              c="var(--error-main)"
              onClick={toggleSignoutModal}
            >
              Sign Out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <SignoutModal
          openeda={signoutModal}
          onClose={toggleSignoutModal}
          onSubmit={() => {
            logout();
            return navigate('/login');
          }}
        />
      </>
    );
  }

  return (
    <Button component={Link} to="/login">
      Get Started
    </Button>
  );
};

export default AvatarAuthenticated;
