import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  $LOCAL_REFRESH_TOKEN_KEY,
  $LOCAL_TOKEN_KEY,
} from '~/stores/useAuthStores';

export const apiBearerIam = ({ ...options }: AxiosRequestConfig) => {
  const authToken = localStorage.getItem($LOCAL_TOKEN_KEY);
  const refreshToken = localStorage.getItem($LOCAL_REFRESH_TOKEN_KEY);
  const baseURL =
    import.meta.env.NEXUS_API_IAM_URL || 'https://api.nexushehub.com';

  // if (authToken === null) {
  //   if (window.location.pathname !== '/login') {
  //     window.location.replace('/login');
  //   }
  //   // return null;
  // }

  const instance = axios.create({
    baseURL,
    ...options,
  });

  instance.interceptors.request.use((config) => {
    if (authToken) {
      config.headers.set('Authorization', `Bearer ${authToken}`);
    }
    return config;
  });

  instance.interceptors.response.use(
    (config) => {
      return config;
    },
    async (error: AxiosError) => {
      const originalRequest = error.config;
      if (
        (error?.response?.status === 401 ||
          (error.response?.data as any).code === 401) &&
        refreshToken
      ) {
        try {
          const response = await instance.post(
            '/iam/user/v1/refresh-token',
            {
              refresh_token: refreshToken,
            },
            {
              baseURL,
            }
          );

          localStorage.setItem(
            $LOCAL_TOKEN_KEY,
            response?.data?.data?.access_token
          );
          localStorage.setItem(
            $LOCAL_REFRESH_TOKEN_KEY,
            response?.data?.data?.refresh_token
          );
        } catch (err) {
          console.log(err);
          return Promise.reject(error);
        }
      }
      console.log(error);
      return Promise.reject(error);
    }
  );

  return instance;
};
