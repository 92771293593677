import { Image, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { NotFound } from '~/assets/images';
import H5 from '~/components/Typograph/H5';
import H8 from '~/components/Typograph/H8';

export type EmptyListProps = {
  image?: string;
  title: string;
  subtitle: string;
};

export const EmptyList = ({ title, subtitle, image }: EmptyListProps) => {
  const { t } = useTranslation();
  return (
    <Stack gap={64} align="center" w="100%">
      <div>
        <Image loading="lazy" src={image ?? NotFound} h={240} w="auto" />
      </div>
      <Stack gap={24} align="center">
        <H5 ta="center" c="var(--text-secondary)">
          {t(title)}
        </H5>
        <H8 ta="center" c="var(--text-secondary)">
          {t(subtitle)}
        </H8>
      </Stack>
    </Stack>
  );
};
